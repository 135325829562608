.navbar {
    display: flex;
    flex-direction: column;
    background-color: #34495E;
    position: fixed;
    top: 40px;
    left: 0;
    width: 10vw;
    height: calc(100vh - 40px);
    padding: 5px 10px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

.navbar-visible {
    transform: translateX(0);
}

.navbar a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    font-size: calc(1vw + 1vh);
}

@media (max-width: 1024px) {
    .navbar {
        width: 11vw;
    }
}

@media (max-width: 768px) {
    .navbar {
        width: 18vw;
    }
}

.navbar-link {
    color: black;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
    transition: color 0.3s;
}

.navbar-link.active {
    color: #4e6e8d;
}