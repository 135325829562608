.header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #2C3E50;
    color: white;
    position: fixed;
    padding: 5px;
    width: 100%;
    z-index: 1010;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.navbar-toggle {
    cursor: pointer;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    margin-right: 1px;
    margin-left: -5px;
}

.logo {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: calc(1vw + 1vh + .5vmin);
}

.search-input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-right: 5px;
    margin-left: 10px;
    font-size: calc(.5vw + .5vh + .5vmin);
}

.user-info {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px;
}

.username {
    font-size: calc(.5vw + .5vh + .5vmin);
    font-weight: bold;
    margin-left: 15px;
}

.logout-btn {
    background-color: #3498DB;
    color: white;
    border: none;
    padding: 8px 5px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: calc(.5vw + .5vh + .5vmin);
    font-weight: bold;
    margin-right: 13px;
}

.create-btn:hover, .logout-btn:hover {
    background-color: #2980B9;
}
